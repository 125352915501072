<template>
    <div>
        <v-progress-linear indeterminate v-if="is_loading"></v-progress-linear>
        <div class="deep-purple lighten-5 pa-4 pb-1">
            <h4>{{title}}</h4>
        </div>
        <v-data-table  v-if="!is_loading" :headers="headers" :items="the_data" class="elevation-1" :loading="is_loading">
            <template v-slot:[`item.converted`]="{item}">
                {{ item.converted=='Y' ? "Yes" : "No" }}
            </template>
            <template v-slot:[`item.licenced`]="{item}">
                {{ upperCaseFirstLetter(item.licenced) }}
            </template>
            <template v-slot:[`item.passed_state_exam`]="{item}">
                {{ upperCaseFirstLetter(item.passed_state_exam) }}
            </template>
        </v-data-table>
        <v-row v-if="!is_loading">
            <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="exportResults">
                    <v-icon left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
const { Parser } = require('json2csv');
export default {
    name: "QOnboardingRecruitingStatusDrilldown",
    data() {
        return {
            the_data: [],
            agent: null,
            loading: false,
            agent_loading: false,
            datePeriod: {},
            backup_filters: {},
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'TotalAgency'
            },
            statusKey: null,
            title: null,
            level: null,
            date: null
        }
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agent_code != 'undefined' && this.$route.params.agent_code != null) {
                return this.$route.params.agent_code;
            }
            return this.user.Agent.AgentCode;
        },
        headers: function() {
            return [
                {
                    text: 'Recruit',
                    value: 'user_name',
                },
                {
                    text: 'Licenced',
                    value: 'licenced',
                },
                {
                    text: 'Passed State Exam',
                    value: 'passed_state_exam',
                },
                {
                    text: 'Converted',
                    value: 'converted',
                },
            ]
        },
        is_loading: function() {
            return this.loading || this.agent_loading
        },
        export_fields: function() {
            return ['agency_owner_code', 'converted', 'licenced', 'passed_state_exam', 'user_id', 'user_name'];
        }
    },
    methods: {
        updateFilters: function(f) {
            this.filters = f
            this.backup_filters = this.filters
        },
        loadData: function() {
            var g = this
            this.loading = true
            //get the data for the agent for this scorecard.
            if (this.agent == null) {
                return
            }
            if (typeof this.filters == 'undefined' || typeof this.filters.timePeriod == undefined) {
                this.filters = this.backup_filters
                return
            }
            var params = {
                ...this.filters,
                date: this.date
            }

            var q_name = "getDrillDown_submitted"
            QuilityAPI.cancelQRequest(q_name);
            QuilityAPI.getRequest("/api/private/scorecards/onboarding-recruiting-" + this.level + "/" + this.agent_code + "/drilldown", params, q_name).then(function(json) {
                    g.the_data = json[g.statusKey] || []
                    g.loading = false;
                },
                function(error) {
                    g.loading = false;
                    console.log(error)
                    return error
                })
        },
        loadAgent: function() {
            var g = this
            g.agent_loading = true;
            return QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                g.$set(g, 'agent', json);
                g.agent_loading = false
            }).catch(function(err) {
                console.log(err)
                g.agent_loading = false
            })
        },
        upperCaseFirstLetter(word) {
            if(!word)
            {
                return word
            }

            word = word.toLowerCase()
            return word.charAt(0).toUpperCase() + word.slice(1)
        },
        refreshDrilldown() {
            this.statusKey = this.$route.query.statusKey
            this.title = this.$route.query.title
            this.level = this.$route.query.level
            this.date = this.$route.query.date

            var g = this
            this.backup_filters = this.filters
            this.loadAgent().then(function() {
                g.loadData();
            })
        },
        exportResults: function() {
            var fields = this.exportFields; //this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.the_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify(this.title + " " + this.endDate);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("Recruiting Drilldown Export", { "stats": this.title })
            } catch (err) {
               console.error(err);
            }

        },
    },
    watch: {
        'filters': {
            deep: true,
            handler: function(newV) {
                this.loadData();
            }
        },
        'agent_code': function(newV) {
            this.agent = null
            var g = this
            this.loading = true
            this.the_data = []
            this.loadAgent(this.agent_code).then(function() {
                g.loadData();
            })
        },
        'datePeriod': {
            deep: true,
            handler: function(newV) {
                var g = this
                if (typeof newV == 'undefined') {
                    return
                }
                if (g.filters.endDate != newV.endDate || g.filters.startDate != newV.startDate || g.filters.timePeriod != newV.period) {
                    g.$nextTick(function() {
                        g.updateFilters({
                            ...newV,
                            timePeriod: newV.period,
                            statType: g.filters.statType
                        })
                    })
                }
            }
        },
    },
    components: {
    },
    activated() {
        this.refreshDrilldown()
    }
}
</script>
<style scoped>
</style>