<template>
    <v-container fluid class="my-0 px-0  py-0" height="100%">
        <v-row class="d-flex justify-center">
            <v-card cols="12" v-if="registrant" flat align="stretch" class="text-left">
                <v-card-title>
                    <strong>{{ registrant.FirstName }} {{ registrant.LastName }}</strong>
                </v-card-title>
                <v-card-subtitle>
                    Event: 2022 National Conference
                </v-card-subtitle>
                <template>
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td><b>Registered on:</b></td>
                                <td>{{ registrant.KapokCreated }}</td>
                            </tr>
                            <tr>
                                <td><b>Email:</b></td>
                                <td>{{ registrant.Email }}</td>
                            </tr>
                            <tr>
                                <td><b>Phone:</b> </td>
                                <td>{{ registrant.Phone }}</td>
                            </tr>
                            <tr>
                                <td><b>Address:</b></td>
                                <td>{{ registrant.Address }}<br>
                                    <span v-if="registrant.Address2">{{ registrant.Address2 }}<br /></span>
                                    {{ registrant.City }}, {{ registrant.State }}<br />
                                    {{ registrant.Zip }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </template>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: [
        'registrant'
    ],

    name: "QDrilldownEventsDetail",
    data() {
        return {};
    },
    mounted: function() {},
    computed: {},
    methods: {},

    watch: {},
    components: {}
}

</script>
<style scoped>
blockquote {
    margin-left: 50px;
}

</style>
