<template>
    <highcharts v-if="chartOptions" :options="chartOptions"></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue'

export default {
    name: "QLeadAppsChart",
    props: ['height', 'theData', 'placedData', 'type'], // data passed to component from parent

    computed: {
        chartOptions() {
            if (!this.theData) { return }

            return {
                chart: {
                    type: 'column',
                    plotShadow: false,
                    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
                    zoomType: 'x',
                    height: this.height ? this.height : "30%"
                },
                title: {
                    text: '',
                },
                xAxis: {
                    //type: 'datetime',
                    categories: this.theData.labels
                },
                legend: {
                    enabled: true
                },
                tooltip: {
                    shared: true,
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter: function(v) {
                        var value = this.y
                        return "<span class='highcharts-color-" + this.colorIndex + "'>●</span>" + this.series.name + " : <strong>" + value + "</strong><br/> "
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: .2,
                        borderWidth: 0
                    }
                },
                series: [{
                        type: 'column',
                        name: 'Leads',
                        data: this.theData.leads,
                    },
                    {
                        type: 'column',
                        name: 'Apps',
                        data: this.theData.apps,
                        colorIndex: 4,
                    }

                ],
            }
        }
    },

    components: {
        highcharts: Chart
    }
}
</script>
