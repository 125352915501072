<!-- No longer used, was replaced by the agent directory for AOs and the new agent detail pages...  -->
<template>
    <div>
        <v-progress-linear indeterminate v-if="loading || agent_loading"></v-progress-linear>
        <q-drilldown-agent-data-table :loading="loading || agent_loading" :title="the_title" :the-data="the_data" :headers="headers" v-on:filters="updateFilters" :filters="filters" @reload="loadData" :agent-code="agent_code" :export-fields="export_fields">
            <template #append-top>
                <p> Note: This shows the agent's <strong>Baseshop Total A Leads</strong> or <strong>Personal A Leads</strong>. It is not the agent's Total Agency A Leads.</p>
            </template>
            <template #append-bottom>
                <q-score-card-pie-chart format="integer" v-if="!loading && !agent_loading" :data="pie_chart_data" title="Number of Apps by Leadership Level"></q-score-card-pie-chart>
            </template>
            <template #filters>
                <q-business-time-period-select :dont-reset="false" class="mr-4" ref="periodSelect" v-model="datePeriod" label="Time Period" :allow-current="true" stateKey="stat"></q-business-time-period-select>
            </template>
        </q-drilldown-agent-data-table>
    </div>
</template>
<script>
/*
Force re-rendering of this component on this view...
set a key and anytime the agentcode changes we will increment the componentKey and it will re-render the component. 
 */

import QDrilldownAgentDataTable from '@/components/datatables/Drilldowns/QDrilldownAgentDataTable.vue';
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QScoreCardPieChart from '@/components/stats/scorecards/QScoreCardPieChart.vue'
export default {
    name: "QALeadsDrilldown",
    props: [],
    data() {
        return {
            the_data: null,
            agent: null,
            loading: false,
            agent_loading: false,
            datePeriod: {},
            backup_filters: {},
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'TotalAgency'
            }
        }
    },
    mounted: function() {
        this.filters = {
            endDate: this.$route.query.endDate,
            startDate: this.$route.query.startDate,
            timePeriod: this.$route.query.timePeriod,
            statType: this.$route.query.statType
        }
        this.datePeriod = {
            endDateFormat: this.$route.query.endDate,
            startDateFormat: this.$route.query.startDate,
            period: this.$route.query.timePeriod
        }
        this.$refs.periodSelect.selectOption(this.$route.query.endDate, this.$route.query.timePeriod)
        var g = this
        this.backup_filters = this.filters
        this.loadAgent(this.agent_code).then(function() {
            g.loadData();
        })
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agent_code != 'undefined' && this.$route.params.agent_code != null) {
                return this.$route.params.agent_code;
            }
            return this.user.Agent.AgentCode;
        },
        headers: function() {
            return [{
                    text: 'Agent',
                    value: 'AgentName',
                },
                {
                    text: 'Title',
                    value: 'LeadershipLevel',
                },
                {
                    text: 'Contract Level',
                    value: 'ContractLevel',
                },
                {
                    text: 'A Leads',
                    value: 'Amount',
                    align: "right"
                }
            ]
        },
        the_title: function() {
            if (typeof this.agent == 'undefined' || this.agent == null) {
                return 'A Lead Details'
            }
            return this.agent.AgentName + ' A Lead Details'
        },
        pie_chart_data: function() {
            if (this.the_data == null) {
                return
            }
            var r = {}
            if (this.filters.statType == "TotalAgency") {
                var baseshopTotal = 0;
                this.the_data.forEach(function(i) {
                    if (i.IsAgencyOwner) {
                        r[i.AgentName] = i.Amount;
                    } else {
                        baseshopTotal = baseshopTotal + i.Amount
                    }
                })
                r.Baseshop = baseshopTotal;
            } else {
                this.the_data.forEach(function(i) {
                    r[i.AgentName] = i.Amount;
                })
            }
            return r;
        },
        export_fields: function() {
            return ['AgentID', 'AgentCode', 'AgentName', 'OptID', 'Amount', 'LeadershipLevel', 'ContractLevel', 'AgentEmail', 'StreetAddress', 'City', 'County', 'State', 'Zip', 'AgentPhone', 'Status', 'Division'];
        }
    },
    methods: {
        updateFilters: function(f) {
            this.filters = f
            this.backup_filters = this.filters
        },
        loadData: function() {
            var g = this
            this.loading = true
            //get the data for the agent for this scorecard.
            if (this.agent == null) {
                return
            }
            if (typeof this.filters == 'undefined' || typeof this.filters.timePeriod == undefined) {
                this.filters = this.backup_filters
                return
            }
            var params = {
                ...this.filters
            }
            //if this is just an agency owner, then we override the filter and request the base shop.
            if (params.statType == "TotalAgency" && this.agent.HasTotalAgency === false) {
                params.statType = "Baseshop"
            }
            //if this is a second level drill down, then we have to force baseshop to make the numbers match the previous drill down. 
            if (this.$route.params.forceBaseshop === true) {
                params.statType = "Baseshop"
            }

            g.the_data = []
            var q_name = "getDrillDown_submitted"
            QuilityAPI.cancelQRequest(q_name);
            QuilityAPI.getRequest("/api/private/scorecards/leads/a/" + this.agent_code + "/drilldown", params, q_name).then(function(json) {
                    g.the_data = json.data
                    g.loading = false;
                },
                function(error) {
                    g.loading = false;
                    console.log(error)
                    return error
                })
        },
        loadAgent: function(agentCode) {
            var g = this
            g.agent_loading = true;
            return QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                g.$set(g, 'agent', json);
                g.agent_loading = false
            }).catch(function(err) {
                console.log(err)
                g.agent_loading = false
            })
        },
    },
    watch: {
        'filters': {
            deep: true,
            handler: function(newV) {
                this.loadData();
            }
        },
        'agent_code': function(newV) {
            this.agent = null
            var g = this
            this.loading = true
            this.the_data = []
            this.loadAgent(this.agent_code).then(function() {
                g.loadData();
            })
        },
        'datePeriod': {
            deep: true,
            handler: function(newValue) {
                var g = this
                if (typeof newValue == 'undefined') {
                    return
                }
                if (g.filters.endDate != newValue.endDateFormat
                    || g.filters.startDate != newValue.startDateFormat
                    || g.filters.timePeriod != newValue.period) {
                    g.$nextTick(function() {
                        g.updateFilters({
                            endDate: newValue.endDateFormat,
                            startDate: newValue.startDateFormat,
                            timePeriod: newValue.period,
                            statType: g.filters.statType
                        })
                    })
                }
            }
        },
    },
    components: {
        QDrilldownAgentDataTable,
        QBusinessTimePeriodSelect,
        QScoreCardPieChart
    }
}
</script>
<style scoped>
</style>