<template>
    <highcharts :options="chartOptions"></highcharts>
    <!--<combo-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></combo-chart>//-->
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
import mockdata from '@/store/sample_data/MockDataHighcharts'
import dataTransformer from '@/store/dataTransformer'
import theme from './Theme'

export default {
    name: "QLeadsChart",
    props: ['height', 'theData', 'placedData', 'type'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        //pass the_data as 
        //{
        //  a_leads : {
        //      "Agent Name" : 12334,
        //      "Agent Name" : 12334
        //  },
        //  bonus_leads : {
        //      "Agent Name" : 33,
        //      "Agent Name" : 33
        //  },
        //  labels : [...]
        //}
        return {
            chartOptions: {
                chart: {
                    type: 'column',
                    plotShadow: false,
                    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
                    zoomType: 'x',
                    height: this.height ? this.height : "30%"
                },
                title: {
                    text: '',
                },
                xAxis: {
                    //type: 'datetime',
                    categories: g.theData.labels
                },
                legend: {
                    enabled: true
                },
                tooltip: {
                    shared: true,
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter: function(v) {
                        var value = this.y
                        return "<span class='highcharts-color-" + this.colorIndex + "'>●</span>" + this.series.name + " : <strong>" + value + "</strong><br/> "
                    },
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: .2,
                        borderWidth: 0
                    }
                },
                series: [{
                        type: 'column',
                        name: 'A Leads',
                        data: g.theData.a_leads,
                    },
                    {
                        type: 'column',
                        name: 'Bonus Leads',
                        data: g.theData.bonus_leads,
                        colorIndex: 4,
                    }

                ],
            },
        }
    },
    mounted: function() {
        //this.renderChart(this.chartData, this.options)
        //Chart.
    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
