<template>
    <div>
        <v-card class="pa-0 elevation-0 scorecard-card" height="100%">
            <div :class="'pa-1 pt-2 title-head ' + cardStyle">
                Agent Progress Report
            </div>
            <div :class="'pa-3 body-stat ' + cardStyle" >
                <p><small>Due to potentially high generation time this report is only available for download.</small></p>
                <v-btn @click="downloadData">Export</v-btn>
            </div>
        </v-card>
    </div>
</template>
<script>

import QuilityAPI from '@/store/API/QuilityAPI.js'
import {debounce} from "vue-debounce";


export default {
    name: "QSummitAgentProgressReport",

    props: {
        filters: {
            type: Object,
            default: {
                timePeriod: 'month',
                statType: 'TotalAgency'
            }
        },
        agentCode: {
            type: String
        },
        cardStyle: {
            type: String,
            default: ''
        }
    },

    data() {
        return {

        }
    },

    computed: {

    },

    methods: {

        downloadData: debounce(function() {

            var params = {
                statType: this.filters.statType,
                itemsPerPage: "csv",
                //'attr': 'LeadershipLevel,BaseshopOwnerAgentName,UplineAgentName,SFGDirectAgentName'
            }
            this.dispatchJob({
                'method': "GET",
                'path': '/api/private/scorecards/summit-agent-progress/' + this.agentCode,
                'params': params,
                'JobName': "Summit Agent Progress Export"
            });
        }, 200),

    },

    watch: {

    },

    components: {

    }
}
</script>
