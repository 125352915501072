<template>
    <div>
        <highcharts :options="chartOptions"></highcharts>
        <div class="stat-label" v-if="hasNegativeNumbers">This month includes chargebacks from the following carriers:
            <span v-for="(d,k) in data" :key="k">
                <span v-if="d < 0">{{k}} ({{formatCurrency(d)}})</span>
            </span>
        </div>
    </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
//import mockdata from '@/store/sample_data/MockDataHighcharts'
import theme from './Theme'
import dataTransformer from '@/store/dataTransformer'

export default {
    name: "QPieChartCarrierPlaced2",
    props: ['data', 'title', 'subtitle', 'name', 'format'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        var data = dataTransformer.pieChart(this.data);
        return {
            chartOptions: {
                chart: {
                    type: 'pie',
                    height: 300
                },
                title: {
                    text: this.title
                },
                subtitle: {
                    text: this.subtitle
                },

                accessibility: {
                    announceNewData: {
                        enabled: true
                    },
                    point: {
                        valueSuffix: '%'
                    }
                },

                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.percentage:.1f}%',
                            formatter: function(point_label, options) {
                                return this.key + ' : ' + g.formatCurrency(this.y) + " ({point.y:.1f}%)"
                            }
                        }
                    }
                },

                tooltip: {
                    formatter: function(point_label, options) {
                        return this.key + ' : ' + g.formatCurrency(this.y)
                    }
                    //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },

                series: [{
                    name: "Carriers",
                    colorByPoint: true,
                    data: data
                }],

            }
        }
    },
    mounted: function() {},
    computed: {
        hasNegativeNumbers: function() {
            var r = false
            var g = this
            if (!g.data) {
                return false;
            }
            Object.keys(g.data).forEach(function(k) {
                if (g.data[k] < 0) {
                    r = true;
                }
            })
            return r;
        }
    },
    methods: {
        updateChartData: function(data) {
            this.chartOptions.series[0].data = dataTransformer.pieChart(this.data);
        }
    },
    watch: {
        'data': function(newV) {
            this.updateChartData(newV)
        }
    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
