<!-- No longer used, was replaced by the agent directory for AOs and the new agent detail pages...  -->
<template>
    <div>
        <v-progress-linear indeterminate v-if="loading || agent_loading"></v-progress-linear>
        <QDrilldownAgentDataTable
            :loading="loading || agent_loading"
            :title="the_title"
            :the-data="the_data"
            :headers="headers"
            :filters="filters"
            :agent-code="agent_code"
            :export-fields="export_fields"
            v-on:filters="updateFilters"
            @reload="loadData"
        >
            <template #append-top>
                <p> Note: APV is the agent's <strong>Baseshop APV</strong> or <strong>Personal Production APV</strong>. It is not the agent's Total Agency APV.</p>
            </template>
            <template #append-bottom>
                <QScoreCardPieChart
                    v-if="!loading && !agent_loading"
                    :data="pie_chart_data"
                />
            </template>
            <template #filters>
                <QBusinessTimePeriodSelect
                    v-model="datePeriod"
                    :dont-reset="false"
                    :allow-current="true"
                    label="Time Period"
                    stateKey="stat"
                    ref="periodSelect"
                    class="mr-4"
                />
            </template>
        </QDrilldownAgentDataTable>
    </div>
</template>

<script>
/*
Force re-rendering of this component on this view...
set a key and anytime the agentcode changes we will increment the componentKey and it will re-render the component.
 */
import QDrilldownAgentDataTable from '@/components/datatables/Drilldowns/QDrilldownAgentDataTable.vue';
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QScoreCardPieChart from '@/components/stats/scorecards/QScoreCardPieChart.vue'

export default {
    name: "QResetsDrilldown",
    data() {
        return {
            the_data: null,
            agent: null,
            loading: false,
            agent_loading: false,
            datePeriod: {},
            backup_filters: {},
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'TotalAgency'
            },
        }
    },
    mounted() {
        this.filters = {
            endDate: this.$route.query.endDate,
            startDate: this.$route.query.startDate,
            timePeriod: this.$route.query.timePeriod,
            statType: this.$route.query.statType
        }
        this.datePeriod = {
            endDateFormat: this.$route.query.endDate,
            startDateFormat: this.$route.query.startDate,
            period: this.$route.query.timePeriod
        }
        this.$refs.periodSelect.selectOption(this.$route.query.endDate, this.$route.query.timePeriod)
        this.backup_filters = this.filters
        this.loadAgent().then(() => {
            this.loadData();
        })
    },
    computed: {
        agent_code() {
            if (this.$route.params.agent_code) {
                return this.$route.params.agent_code;
            }
            return this.user.Agent.AgentCode;
        },
        headers() {
            return [
                {
                    text: 'Agent',
                    value: 'AgentName',
                },
                {
                    text: 'Title',
                    value: 'LeadershipLevel',
                },
                {
                    text: 'Contract Level',
                    value: 'ContractLevel',
                },
                {
                    text: 'APV',
                    value: 'APV',
                    align: "right"
                },
            ]
        },
        the_title() {
            if (!this.agent) {
                return 'Resets Details'
            }
            return this.agent.AgentName + ' Resets Details'
        },
        pie_chart_data() {
            if (this.the_data == null) {
                return
            }
            const data = {}
            if (this.filters.statType == "TotalAgency") {
                let baseshopTotal = 0;
                this.the_data.forEach((item) => {
                    if (item.IsAgencyOwner) {
                        data[item.AgentName] = item.APV;
                    } else {
                        baseshopTotal = baseshopTotal + item.APV
                    }
                })
                data.Baseshop = baseshopTotal;
            } else {
                this.the_data.forEach((item) => {
                    data[item.AgentName] = item.APV;
                })
            }
            return data;
        },
        //these fields are being used???
        //exporting exactly what is in the API returned data.
        export_fields() {
            return ['AgentID', 'AgentCode', 'AgentName', 'OptID', 'APV', 'LeadershipLevel', 'ContractLevel', 'AgentEmail', 'StreetAddress', 'City', 'County', 'State', 'Zip', 'AgentPhone', 'Status', 'Division'];
        },
    },
    methods: {
        updateFilters(filters) {
            this.filters = filters
            this.backup_filters = this.filters
        },
        loadData() {
            this.loading = true
            //get the data for the agent for this scorecard.
            if (this.agent == null) {
                return
            }
            if (!this.filters?.timePeriod) {
                this.filters = this.backup_filters
                return
            }
            const params = {
                ...this.filters
            }

            // if this is a second level drill down, then we have to force baseshop to make the numbers match the previous drill down.
            if (this.$route.params.forceBaseshop === true) {
                params.statType = "Baseshop"
            }

            this.the_data = []
            var q_name = "getDrillDown_resets"
            QuilityAPI.cancelQRequest(q_name);
            QuilityAPI.getRequest("/api/private/scorecards/resets/" + this.agent_code + "/drilldown", params, q_name)
                .then((json) => {
                    this.the_data = json.data
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                    console.log(error)
                    return error
                })
        },
        loadAgent() {
            this.agent_loading = true;
            return QuilityAPI.getAgentStateless(this.agent_code).then((json) => {
                this.agent = json
                this.agent_loading = false
            }).catch((err) => {
                console.log(err)
                this.agent_loading = false
            })
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.loadData();
            }
        },
        agent_code() {
            this.agent = null
            this.loading = true
            this.the_data = []
            this.loadAgent().then(() => {
                this.loadData();
            })
        },
        datePeriod: {
            deep: true,
            handler(newValue) {
                if (typeof newValue == 'undefined') {
                    return
                }
                if (this.filters.endDate != newValue.endDateFormat || this.filters.startDate != newValue.startDateFormat || this.filters.timePeriod != newValue.period) {
                    this.$nextTick(() => {
                        this.updateFilters({
                            endDate: newValue.endDateFormat,
                            startDate: newValue.startDateFormat,
                            timePeriod: newValue.period,
                            statType: this.filters.statType
                        })
                    })
                }
            }
        },
    },
    components: {
        QDrilldownAgentDataTable,
        QBusinessTimePeriodSelect,
        QScoreCardPieChart,
    },
}
</script>

<style scoped></style>
