<template>
    <div :height="cardHeight" class="leaderboard">
        <div class="pa-4 pb-1" style="background-color: #0083EB;">
            <h4 class="white--text">Event Registrations</h4>
        </div>
        <div class="pa-4 pb-1" v-if="!loading">
            <h4>{{ agentName }}</h4>
            <h6> {{ personalInvites }} {{ registrationType }} {{ personalInvites != 1 ? 'Registrations' : 'Registration'}}
                <!-- Used for testing purposes -->
                <v-btn icon @click="resetData">
                    <v-icon small>fas fa-sync-alt</v-icon>
                </v-btn>
                <br>
                <v-btn text small color="#0083EB" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Registrations
                </v-btn>
            </h6>
        </div>
        <div class="px-4 pb-3 pt-3 white--text" v-if="!loading" style="background-color: #66b5f3;">
            <!-- Need to figure out how we're going to get the names of these events... -->
            <h6 class=""> Event: 2022 National Conference </h6>
        </div>
        <QExpandableDataTable v-if="theData && theData.length > 0" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" item-key="FullName" :headers="computedHeaders" :visibleHeaders="visibleHeaders" :items="computedData" :class="table_class" :loading="loading" @click:row="launchModal" :footer-props="{itemsPerPageOptions:[10,25,50,100, -1]}" color="#0083EB">
            <template v-slot:item.RegistrationDate="{ item }">
                <span v-if="item.RegistrationDate">{{formatDate(item.RegistrationDate)}}</span>
            </template>
            <template v-slot:item.Phone="{ item }">
                <span v-if="item.Phone">{{formatPhoneNumber(item.Phone)}}</span>
            </template>
            <template v-slot:item.Ticket_Data="{ item }">
                <span v-if="item.Ticket_Data.SignedByAgent"> {{ item.Ticket_Data.SignedByAgent.AgentName }}</span>
            </template>
            <!--
            <template v-slot:item.Registrations="{ item }">
                <span v-if="item.IsHQAgent">
                    <router-link :to="{path: '/my_stats/drilldown/events/' + item.Agent.AgentCode, query:{statType: 'personal'}}">
                        {{ item.Registrations }}
                    </router-link>
                </span>
                <span v-else-if="registrationType == 'Personal'">{{ item.Registrations }}</span>
                <span v-else>0</span>
            </template>
        -->
        </QExpandableDataTable>
        <!--
        <v-dialog v-model="events_policy_detail" width="500">
            <v-card v-if="current_event" class="pa-6 text-center" min-height="">
                <div>
                    <q-drilldown-events-detail :registrant="current_event.Ticket_Data"></q-drilldown-events-detail>
                </div>
                <div v-for="(spouse_event, index) in current_event.Spouse_Ticket_Data" :key="index">
                    <q-drilldown-events-detail :registrant="spouse_event"></q-drilldown-events-detail>
                </div>
            </v-card>
        </v-dialog>
        <v-btn to="/my_stats/scorecard/events/" color="#66b5f3" outlined class="mt-5">
            Back
        </v-btn>
    -->
    </div>
</template>
<script>
const { Parser } = require('json2csv');
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import QDrilldownEventsDetail from "@/components/datatables/Drilldowns/QDrilldownEventsDetail"
import QExpandableDataTable from '../QExpandableDataTable.vue';

export default {
    name: "QDrilldownEventsAggregateDataTable",
    props: {
        'title': {
            type: String,
            default: 'Leaderboard'
        },
        'subtitle': {
            type: String,
            default: null
        },
        'rows': {
            type: Number,
            default: 25
        },
        'moreLink': {
            type: String,
            default: null
        },
        'theData': {
            type: Array,
            default: function() {
                return []
            }
        },
        'headers': {
            type: Array,
            default: function() {
                return []
            }
        },
        'startDate': {
            type: String,
            default: null
        },
        'endDate': {
            type: String,
            default: null
        },
        'loading': {
            type: Boolean,
            default: false
        },
        'allowDrilldown': {
            type: Boolean,
            default: true
        },
        'timePeriod': {
            type: String,
            default: "month"
        },
        'agentCode': {
            type: String,
            default: ""
        },
        'agentName': {
            type: String,
            default: ""
        },
        'filters': {
            type: Object,
            default: function() {
                return {}
            }
        },
        'personalInvites': {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            selected: [],
            search: null,
            sumFields: [
                'Apv',
                'Apps',
                'Leads',
                'Amount',
                'ALeadsAmount',
                'BonusLeadsAmount',
                'OtherLeadsAmount'
            ],
            events_policy_detail: false,
            current_event: false,
        }
    },
    computed: {
        registrationType: function() {
            let label = ""
            switch (this.$route.query.statType) {
                case 'personal':
                    label = 'Personal'
                    break
                case 'baseshop':
                    label = 'Baseshop'
                    break
                case 'totalagency':
                    label = 'TA'
                    break
                case 'directagency':
                    label = 'DA'
                    break
                default:
                    break
            }

            return label
        },
        cardHeight: function() {
            if (this.$vuetify.breakpoint.xs) {
                return null
            }
            return 257 + (this.rows * 56) + "px"
        },
        table_class: function() {
            if (this.allowDrilldown) {
                return 'elevation-1 leaderboard-datatable pointer'
            }
            return 'elevation-1 leaderboard-datatable'
        },
        export_fields: function() {
            return ['FirstName','LastName', 'RegisteringAgent','RegistrationDate','Tickets','Email','Phone']
        },
        exportable_object: function() {
            let exportedData = []

            for (const registrationData of this.theData) {
                exportedData.push({
                    FirstName: registrationData.FirstName,
                    LastName: registrationData.LastName,
                    RegisteringAgent: registrationData.Ticket_Data.SignedByAgent ? registrationData.Ticket_Data.SignedByAgent.AgentName : null,
                    RegistrationDate: registrationData.Ticket_Data.KapokCreated,
                    Tickets: registrationData.Tickets,
                    Email: registrationData.Email,
                    Phone: QDataTableMixin.methods.formatPhoneNumber(registrationData.Phone)
                })
            }

            return exportedData
        },

        computedData() {
            return this.theData.map((row) => ({ ...row, FullName: `${row.FirstName} ${row.LastName}` }))
        },

        computedHeaders() {
            return [ { value: 'FullName', text: 'Name' }, ...this.headers.slice(2), ]
        },

        visibleHeaders() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    { value: 'Tickets' },
                    { value: 'FullName' }
                ]
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return [
                    ...this.computedHeaders.slice(0, 5),
                ]
            }

            return this.computedHeaders
        },
    },
    methods: {
        row_classes: function(item) {
            return 'test'
        },
        exportResults: function() {
            //these fields are being used???
            //exporting exactly what is in the API returned data.
            var fields = this.export_fields;
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.exportable_object);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify(this.title);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                // this.logActivity("Event Export", { "leaderboard": this.title })
            } catch (err) {
                console.error(err);
            }

        },
        resetData: function() {
            this.$emit('reload');
        },
        drillDownAgent: function(item) {

            var params = {
                agent_code: item.UplineAgentCode,
            }

            this.$router.push({
                name: this.$route.name,
                params: params,
                query: {
                    endDate: this.$route.query.endDate,
                    startDate: this.$route.query.startDate,
                    timePeriod: this.$route.query.timePeriod,
                    statType: (!item.IsAgencyOwner || !this.allowDrilldown) ? 'personal' : 'baseshop' // force baseshop on the second layer of drill downs.
                }
            });
        },
        launchModal: function(item) {
            this.events_policy_detail = true
            this.current_event = item
        },
        getAgentPreferredName: function(item) {
            if (item == null) {
                return ''
            }

            if (typeof item.PreferredName != 'undefined' && item.PreferredName != null && item.PreferredName != '') {
                return item.PreferredName
            }
            if (typeof item.PreferredAgentName != 'undefined' && item.PreferredAgentName != null && item.PreferredAgentName != '') {
                return item.PreferredAgentName
            }
            return item.AgentName

        },
        sumField(key) {
            return this.theData.reduce((a, b) => a + (b[key] || 0), 0)
        }
    },
    components: {
        QDrilldownEventsDetail,
        QDataTableMixin,
        QExpandableDataTable,
    }
}

</script>
<style scope>
.leaderboard table .agent-row td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border-top: 1px solid black;
}

.v-application .leaderboard a {}

.agent-strong {
    font-weight: 900;
}

.rank-header {
    white-space: nowrap;
}

.pointer, .pointer tr {
    cursor: default;
}

</style>
