<template>
    <div>
        <!--
        Make sure the key is unique.
        api endpoint will be the part after "/api/private/scorecards/..."
        drill down rout is the "NAME" of the route in the router file.
        The style is the css class name for the section of scorecards to customize colors
        agent code will get passed from the view. Should come from url string or default to the current logged in user.
        filters will come from the view as well... may or may not be used.
        format can be "Currency" "Percentage" or don't pass anything for int.
        //-->
        <q-score-card :key="'QScoreCardSummitCompleted' + phase"
                      :title="'Completed ' + phase + ' Phase'"
                      api-endpoint="summit-phase-completed"
                      drill-down-route="Drilldown_Phase_Completed"
                      :filters="{...filters, phase: phase}"
                      card-style="summit-stats" :agent-code="agentCode"
                      :data-requires-filters="true" :allow-refresh="false" :hide-goal="true"
        >
            Shows the number of agents that have completed Summit's {{ phase }} Phase during the time period.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardSummitPhaseCompleted",
    props: [
        "agentCode",
        "filters",
        "phase"
    ],
    data: function() {
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}
</script>

<style scoped>
</style>
